* , *:before, *:after { 
  box-sizing:border-box; 
  -moz-box-sizing:border-box; 
  -webkit-box-sizing:border-box; 
  -ms-box-sizing:border-box;
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

#accueil {
  height: 100%;
  width: 100%;
}

#accueil form {
  display: flex;
  flex-direction: column;
  height: 80%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

#accueil form input {
  margin: 10px;
}

button, input[type=submit] {
  max-width: 700px;
  padding: 10px 20px 10px 20px;
}

#game {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

#showPlayers {
  border: 1px solid #333;
  background-color: rgba(209, 202, 235, 0.815);
  margin: 0px;
  height: 50px;
  cursor: pointer;
}

#players {
  height: 100%;
  padding: 10px;
  border-right: 1px solid #ccc;
}

#players #header {
  font-size: large;
  margin-bottom: 40px;
}

#players #curPlayer {
  font-weight: bold;
}

#gameZone {
  flex: 1;
  flex-direction: column;
  padding: 20px;
  height: 100%;
}

#gameZone input[type=text] {
  margin: 10px;
}

#options {
  width: 50%;
  margin-top: 50px;
}

#lienPartie {
  width: 100%;
  max-width: 500px;
}

#archives {
  padding: 20px;
  height: 100%;
}

.player {
  color: #333;
}

.player-offline {
  color: #a33;
}

.bold {
  font-weight: bold;
}

.card {
  flex: 1;
  border: 1px solid #333;
  margin: 20px 0 20px 0;
  width: 100%;
  max-width: 700px;
  max-height: 400px;
  overflow: auto;
}

.card textarea {
  width: 100%;
  height: 100%;
  max-width: 700px;
  max-height: 390px;
  box-sizing: border-box;
  resize: none;
  text-align: center;
  font-size: 40px;
  border: none;
}

.card img {
  max-height: 100%;
  max-width: 100%;
}

.card p {
  word-break: break-word;
  text-align: center;
  font-size: 40px;
}

#submitDessin {
  margin-top: 20px;
}

#boutonsFinPartie {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.boutonsSequences button, #boutonsFinPartie button {
  margin-top: 5px;
  margin-right: 5px;
}

.boutonsSequences {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 700px;
}

#formTexte {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.btnValider {
  background-color: rgb(140, 236, 140);
}

.btnEffacer {
  background-color: rgb(252, 174, 57);
}
